// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avaliacao-de-empresas-avaliacao-de-agio-de-aquisicao-js": () => import("./../../../src/pages/avaliacao-de-empresas/avaliacao-de-agio-de-aquisicao.js" /* webpackChunkName: "component---src-pages-avaliacao-de-empresas-avaliacao-de-agio-de-aquisicao-js" */),
  "component---src-pages-avaliacao-de-empresas-avaliacao-de-marcas-js": () => import("./../../../src/pages/avaliacao-de-empresas/avaliacao-de-marcas.js" /* webpackChunkName: "component---src-pages-avaliacao-de-empresas-avaliacao-de-marcas-js" */),
  "component---src-pages-avaliacao-de-empresas-index-js": () => import("./../../../src/pages/avaliacao-de-empresas/index.js" /* webpackChunkName: "component---src-pages-avaliacao-de-empresas-index-js" */),
  "component---src-pages-avaliacao-de-empresas-metodos-de-avaliacao-de-empresas-metodologia-de-valuation-js": () => import("./../../../src/pages/avaliacao-de-empresas/metodos-de-avaliacao-de-empresas-metodologia-de-valuation.js" /* webpackChunkName: "component---src-pages-avaliacao-de-empresas-metodos-de-avaliacao-de-empresas-metodologia-de-valuation-js" */),
  "component---src-pages-avaliacao-de-empresas-metodos-de-valuation-de-empresas-js": () => import("./../../../src/pages/avaliacao-de-empresas/metodos-de-valuation-de-empresas.js" /* webpackChunkName: "component---src-pages-avaliacao-de-empresas-metodos-de-valuation-de-empresas-js" */),
  "component---src-pages-business-plan-consultoria-plano-de-negocios-js": () => import("./../../../src/pages/business-plan/consultoria-plano-de-negocios.js" /* webpackChunkName: "component---src-pages-business-plan-consultoria-plano-de-negocios-js" */),
  "component---src-pages-business-plan-index-js": () => import("./../../../src/pages/business-plan/index.js" /* webpackChunkName: "component---src-pages-business-plan-index-js" */),
  "component---src-pages-clientes-js": () => import("./../../../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-consultoria-bndes-index-js": () => import("./../../../src/pages/consultoria-bndes/index.js" /* webpackChunkName: "component---src-pages-consultoria-bndes-index-js" */),
  "component---src-pages-consultoria-em-planejamento-estrategico-js": () => import("./../../../src/pages/consultoria-em-planejamento-estrategico.js" /* webpackChunkName: "component---src-pages-consultoria-em-planejamento-estrategico-js" */),
  "component---src-pages-consultoria-empresarial-erros-gestao-financeira-startup-js": () => import("./../../../src/pages/consultoria-empresarial/erros-gestao-financeira-startup.js" /* webpackChunkName: "component---src-pages-consultoria-empresarial-erros-gestao-financeira-startup-js" */),
  "component---src-pages-consultoria-empresarial-index-js": () => import("./../../../src/pages/consultoria-empresarial/index.js" /* webpackChunkName: "component---src-pages-consultoria-empresarial-index-js" */),
  "component---src-pages-consultoria-empresarial-precificacao-inadequada-js": () => import("./../../../src/pages/consultoria-empresarial/precificacao-inadequada.js" /* webpackChunkName: "component---src-pages-consultoria-empresarial-precificacao-inadequada-js" */),
  "component---src-pages-consultoria-pme-js": () => import("./../../../src/pages/consultoria-pme.js" /* webpackChunkName: "component---src-pages-consultoria-pme-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-fusoes-e-aquisicoes-js": () => import("./../../../src/pages/fusoes-e-aquisicoes.js" /* webpackChunkName: "component---src-pages-fusoes-e-aquisicoes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pesquisa-de-mercado-index-js": () => import("./../../../src/pages/pesquisa-de-mercado/index.js" /* webpackChunkName: "component---src-pages-pesquisa-de-mercado-index-js" */),
  "component---src-pages-pesquisa-de-mercado-pesquisa-de-satisfacao-da-ans-js": () => import("./../../../src/pages/pesquisa-de-mercado/pesquisa-de-satisfacao-da-ans.js" /* webpackChunkName: "component---src-pages-pesquisa-de-mercado-pesquisa-de-satisfacao-da-ans-js" */),
  "component---src-pages-pesquisa-de-mercado-pesquisa-de-satisfacao-js": () => import("./../../../src/pages/pesquisa-de-mercado/pesquisa-de-satisfacao.js" /* webpackChunkName: "component---src-pages-pesquisa-de-mercado-pesquisa-de-satisfacao-js" */),
  "component---src-pages-reestruturacao-financeira-index-js": () => import("./../../../src/pages/reestruturacao-financeira/index.js" /* webpackChunkName: "component---src-pages-reestruturacao-financeira-index-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

